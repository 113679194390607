<template>
  <master-layout
    :on_active="on_active"
    v-if="user.role >= 3"
    @create-card="createCard"
    class="relative"
  >
    <main class="mx-auto w-87 pb-11">
      <section>
        <client-form
          v-if="show_modal"
          @close-card-modal="createCard()"
        ></client-form>
      </section>
      <section>
        <client-profile
          @previous-page="previousPage"
          :view_client_profile="client"
          :id="id"
        ></client-profile>
      </section>

      <section
        class="absolute bottom-6 left-6 xl:left-96"
        v-if="new_app_version"
        @click="updateVersion"
      >
        <div class="cursor-pointer">
          <div
            class="
              bg-primary
              animate-bounce
              shadow-md
              hover:shadow-xl
              px-4
              py-4
              text-white text-sm
              rounded-md
            "
          >
            New Version Available! Click to update
          </div>
        </div>
      </section>
    </main>
  </master-layout>
</template>

<script>
import { mapState } from 'vuex'
import ClientProfile from '../../components/Clients/client-profile/ClientProfile.vue'
import ClientForm from '../../components/Create/ClientForm.vue'
import MasterLayout from '../../components/Layout/MasterLayout.vue'
export default {
  components: { MasterLayout, ClientForm, ClientProfile },
  props: {
    on_active: {
      default: 'clients',
      type: String
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      show_modal: false
    }
  },
  created() {
    this.$store.dispatch('clients/read', this.id)
  },
  computed: {
    ...mapState('clients', ['client']),

    user() {
      return this.$store.state.user
    },

    new_app_version() {
      return this.$store.state.new_app_version
    }
  },
  methods: {
    createCard() {
      this.show_modal = !this.show_modal
    },
    previousPage() {
      this.$router.push({
        name: 'Client'
      })
    },

    updateVersion() {
      this.$store.dispatch('addAppVersionToLocalStorage')
    }
  },
  beforeMount() {
    this.$store.dispatch('middleware/isLogged')
  },
  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style></style>
