<template>
  <section class="mt-14">
    <div
      class="
        block
        md:grid md:grid-cols-3 md:gap-x-16 md:items-center
        space-y-3
        md:space-y-0
      "
    >
      <nav class="col-span-2">
        <ul class="inline-flex">
          <li
            class="
              py-1
              w-28
              shadow
              text-center
              mr-2
              rounded-md
              hover:bg-primary hover:text-white
              cursor-pointer
            "
            :class="{
              'text-white bg-primary': pet,
              'text-black bg-gray-200': !pet
            }"
            @click="petBtn()"
          >
            Pets
          </li>
          <li
            class="
              py-1
              w-28
              shadow
              text-center
              rounded-md
              hover:bg-primary hover:text-white
              cursor-pointer
            "
            :class="{
              'text-white bg-primary': farm,
              'text-black bg-gray-200': !farm
            }"
            @click="farmBtn()"
          >
            Farms
          </li>
        </ul>
      </nav>
    </div>

    <section class="mt-8">
      <pet
        v-if="pet"
        :client_id="client_id"
        @view-profile="viewPetProfile"
      ></pet>
    </section>
  </section>
</template>

<script>
import Pet from './client-pet/Pet.vue'
export default {
  components: { Pet },
  props: {
    client_id: String
  },
  data() {
    return {
      farm: false,
      pet: true
    }
  },
  methods: {
    farmBtn() {
      this.neutral()
      this.farm = true
    },
    petBtn() {
      this.neutral()
      this.pet = true
    },
    neutral() {
      this.farm = false
      this.pet = false
    },
    viewPetProfile(val) {
      this.$emit('view-pet-profile', val)
    }
  }
}
</script>

<style></style>
