<template>
  <main>
    <div>
      <a
        href="javascript:;"
        @click="$emit('previous-page')"
        class="flex space-x-2 text-primary mb-6"
      >
        <arrowback-icon></arrowback-icon>
        <span>back</span>
      </a>
    </div>
    <section class="lg:grid lg:grid-cols-3 lg:gap-x-12">
      <section class="col-span-2">
        <section class="bg-white pt-3 pb-12 px-6 overflow-x-hidden">
          <div
            class="flex align-middle items-center justify-end sm:mb-1 space-x-4"
          >
            <div
              @click="editProfile"
              class="p-1 cursor-pointer shadow-sm rounded-full hover:shadow-md"
            >
              <edit-icon
                :color="'text-gray-400 hover:text-black'"
                :width="'w-6'"
                :height="'h-6'"
              ></edit-icon>
            </div>
          </div>
          <div class="md:grid md:grid-cols-4 items-center md:space-x-6">
            <div class="mt-8 md:mt-0 md:col-span-1">
              <div class="flex justify-center">
                <user-icon :height="'h-36'" :width="'w-36'"></user-icon>
              </div>
            </div>
            <div class="col-span-3">
              <div class="sm:pr-4">
                <div
                  class="
                    md:flex md:align-middle md:items-center
                    mb-2
                    text-center
                  "
                >
                  <h1 class="text-2xl font-semibold">
                    {{
                      view_client_profile.client_details
                        ? view_client_profile.client_details.name
                        : ''
                    }}
                  </h1>
                  <!-- <div
                    class="
                      px-2
                      py-1
                      rounded-3xl
                      text-sm
                      font-medium
                      md:ml-8
                      mx-auto
                    "
                    :class="{
                      'text-white bg-active_pill_color w-28':
                        view_client_profile.isActive == true,
                      'text-black bg-gray-400 w-32':
                        view_client_profile.isActive == false
                    }"
                  >
                    <span v-if="view_client_profile.isActive == true"
                      >pet has report</span
                    >
                    <span v-if="view_client_profile.isActive == false"
                      >pet has no report</span
                    >
                  </div> -->
                </div>
                <div class="flex justify-center md:block">
                  <small class="text-base">{{
                    view_client_profile.client_details
                      ? view_client_profile.client_details.bus_name
                      : ''
                  }}</small>
                </div>
                <div class="mt-5">
                  <div
                    class="
                      grid grid-cols-1
                      md:grid-cols-3
                      space-y-4
                      md:space-y-0
                    "
                  >
                    <div class="col-span-1">
                      <p class="text-gray-500 text-opacity-80 text-base">
                        Phone
                      </p>
                      <p>
                        {{
                          view_client_profile.client_details
                            ? view_client_profile.client_details.phone
                            : ''
                        }}
                      </p>
                    </div>
                    <div class="col-span-1">
                      <p class="text-gray-500 text-opacity-80 text-base">
                        Email
                      </p>
                      <p>
                        {{
                          view_client_profile.client_details
                            ? view_client_profile.client_details.email
                            : ''
                        }}
                      </p>
                    </div>
                    <div class="col-span-1">
                      <p class="text-gray-500 text-opacity-80 text-base">
                        Address
                      </p>
                      <p>
                        {{
                          view_client_profile.client_details
                            ? view_client_profile.client_details.address
                            : ''
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
    <client-tab :client_id="id"></client-tab>
    <edit-client-modal
      v-if="edit_client_modal == true"
      :client_detail="view_client_profile"
      @close-edit-modal="editProfile"
    ></edit-client-modal>
  </main>
</template>

<script>
import ArrowbackIcon from '../../Icons/arrowbackIcon.vue'
import EditIcon from '../../Icons/editIcon.vue'
import EditClientModal from '../../Edit/EditClientModal.vue'
import UserIcon from '../../Icons/userIcon.vue'
import ClientTab from '../ClientTab.vue'
export default {
  components: {
    EditIcon,
    ArrowbackIcon,
    EditClientModal,
    UserIcon,
    ClientTab
  },
  props: {
    view_client_profile: [Object, Array],
    id: String
  },
  data() {
    return {
      edit_client_modal: false
    }
  },
  methods: {
    editProfile() {
      this.edit_client_modal = !this.edit_client_modal
    }
  }
}
</script>

<style></style>
