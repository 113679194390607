<template>
  <main>
    <section
      class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
    >
      <div
        class="
          shadow-md
          rounded
          bg-white
          py-8
          px-8
          flex flex-col
          justify-center
          text-center
          items-center
          relative
          card
        "
        v-for="(pet, index) in client_pet"
        :key="index"
      >
        <div class="my-4">
          <pet-icon
            :width="'w-20'"
            :height="'h-20'"
            class="text-gray-300"
          ></pet-icon>
        </div>
        <h1 class="text-lg font-medium leading-6">
          {{ pet.pet_info.pet_name }}
        </h1>
        <p class="text-gray-500">{{ pet.pet_info.pet_type }}</p>
        <div class="mt-2">
          <span
            v-if="pet.isActive != false"
            class="
              bg-active_pill_color
              px-3
              py-1
              rounded-3xl
              text-sm
              font-medium
            "
            >Active</span
          >
          <span
            v-if="pet.isActive != true"
            class="bg-gray-300 px-3 py-1 rounded-3xl text-sm font-medium"
            >Not Active</span
          >
          <div class="absolute left-0 top-0 h-1/5 card-top-bar">
            <div class="px-4 py-3 flex justify-between">
              <a
                href="javascript:;"
                @click="petProfile(index)"
                class="hover:text-primary"
              >
                <span>view</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <pet-profile-modal
      @close-edit-modal="petProfile"
      :pet_profile="pet_profile"
      v-if="view_pet_details == true"
    ></pet-profile-modal>

    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import PetIcon from '../../Icons/petIcon.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'
import PetProfileModal from './PetProfileModal.vue'

export default {
  components: {
    PetIcon,
    LoadingModel,
    PetProfileModal
  },
  props: {
    client_id: String
  },
  data() {
    return {
      pet_profile: {},
      pet_option: false,
      view_pet_details: false
    }
  },
  created() {
    this.$store.dispatch('clients/clientPet', this.client_id)
  },
  computed: {
    ...mapState('clients', ['client_pet', 'loading']),
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    petProfile(val) {
      this.pet_profile = this.client_pet[val]
      this.view_pet_details = !this.view_pet_details
    }
  }
}
</script>

<style scoped>
.card:hover {
  @apply bg-gray-50;
  @apply opacity-90;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
}
</style>
